import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import PostList from "../components/PostList"

const OtherIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <>
      <Seo title="All posts" />
      <PostList posts={posts} />
    </>
  )
}

export default OtherIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { nin: ["trải nghiệm", "khoa học", "tâm linh", "sống đẹp"] } } }
    ) {
      nodes {
        excerpt
        timeToRead
        fields {
          slug
        }
        frontmatter {
          date(formatString: "D MMMM YYYY", locale: "vi")
          title
          description
          category
          tags
          cover {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  }
`
